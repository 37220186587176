//Copyright © Yuxi Qin - Check me out at yuxiqin.ca
import React from 'react';

import './Splash.css';
import TextCarousel from './parts/TextCarousel'
import Socials from './parts/Socials'
import Resume from './parts/Resume'

function Splash() {
    // for the role item - have the thing type it out occasionally, cycling between: web developer, graphic designer,
    //https://tilomitra.github.io/infinite/
    //maybe make a loading screen?
    return (
        <section id="home" className="splash-intro">
            <div className="row">
                <div className="col col-sm pfp-holder">
                    <div className="pfp"/>
                </div>
                <div className="col col-md profile-descrip">
                    <TextCarousel/>

                    <p className="name profile-item">Yuxi Qin</p>
                    <p className="blurb profile-item">A focused and versatile Computer Engineering student at McMaster University, passionate about engaging in opportunities within the STEM community. Currently pursuing a career in vehicle test engineering.</p>
                </div>
            </div>

            <div className="row">
                <div className="col col-sm resume-btn-holder">
                    <Resume text="resume" link="https://go.yuxiqin.ca/resume" class="resume-btn" />
                    <Resume text="portfolio" link="https://go.yuxiqin.ca/engineering-portfolio" class="portfolio-btn"/>

                </div>
                <div className="col col-md socials-holder">
                    <Socials />
                </div>
            </div>
        </section>
    )
}

export default Splash;
